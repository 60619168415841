import { elipsisAtLength } from "../utilities/stringHelpers";
import { Item, Mdr } from "./aggregatesSalesApi";

export type AggregatedSales = {
  id: string;
  PurchaserId: number;
  GroupId: string;
  Type: string;
  Period: string;
  WeekDay: string;
  StartTime: Date;
  EndTime: Date;
  Currency: string;
  SalesProvider: string;
  MDRProvider: string;
  MDRInstanceId: number;
  NetAmount: number;
  QuantitySold: number;
  Items: Item[];
  Level1MenuAggregateStatistics: MenuAggregateStatistics[];
  Level2MenuAggregateStatistics: MenuAggregateStatistics[];
  Level3MenuAggregateStatistics: MenuAggregateStatistics[];
};

export type MenuAggregateStatistics = {
  name: string;
  shorttenedName: string;
  id: number;
  netAmount: number;
  quantitySold: number;
  items: Item[];
};

export function calculateMenuStats(
  items: Item[],
  level: "Level1MenuID" | "Level2MenuID" | "Level3MenuID"
): MenuAggregateStatistics[] {
  const aggregateMap = items.reduce<{
    [key: number]: {
      name: string;
      netAmount: number;
      quantitySold: number;
      items: Item[];
    };
  }>((acc, item) => {
    const menuId = item.Mdr[level];
    const menuName = item.Mdr[level.replace("ID", "Name") as keyof Mdr];
    if (!acc[menuId]) {
      acc[menuId] = {
        name: menuName as string,
        netAmount: 0,
        quantitySold: 0,
        items: [],
      };
    }
    acc[menuId].netAmount += item.NetAmount;
    acc[menuId].quantitySold += item.QuantitySold;
    acc[menuId].items.push(item);
    return acc;
  }, {});

  return Object.keys(aggregateMap).map((key) => {
    const id = parseInt(key, 10);
    const { name, netAmount, quantitySold, items } = aggregateMap[id];
    return {
      name: name,
      id: id,
      shorttenedName: elipsisAtLength(name, 18),
      netAmount: netAmount,
      quantitySold: quantitySold,
      items: items,
    };
  });
}

export function getTopDishByNetAmount(items: Item[]): string {
  if (items.length === 0) {
    return "";
  }

  const topDish = items.reduce((maxItem, currentItem) => {
    return currentItem.NetAmount > maxItem.NetAmount ? currentItem : maxItem;
  });

  return topDish.Mdr.DishName;
}

export function getTopLevel3MenuByNetAmount(items: Item[]): string {
  if (items.length === 0) {
    return "";
  }

  const revenueMap = items.reduce<{ [key: string]: number }>((acc, item) => {
    const level3MenuName = item.Mdr.Level3MenuName;
    if (!acc[level3MenuName]) {
      acc[level3MenuName] = 0;
    }
    acc[level3MenuName] += item.NetAmount;
    return acc;
  }, {});

  const topLevel3MenuName = Object.keys(revenueMap).reduce((a, b) =>
    revenueMap[a] > revenueMap[b] ? a : b
  );

  return topLevel3MenuName;
}
