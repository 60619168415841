import {
  Avatar,
  Popover,
  Toggle,
  ToggleOption,
} from "@tag/tag-components-react-v4";
import { FC } from "react";
import { ApplicationUser } from "../../types/user";
import { dummyUsers } from "../../data/dummyUser";

type UserAvatarProps = {
  user: ApplicationUser;
  onUserChange: (userId: string) => void;
};

const UserAvatar: FC<UserAvatarProps> = ({ user, onUserChange }) => {
  const userToggles = dummyUsers.map((dUser) => (
    <ToggleOption key={dUser.userId} value={dUser.userId}>
      {dUser.organisationName}
    </ToggleOption>
  ));

  return (
    <div>
      <Popover
        popoverFor={
          <Avatar
            src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&h=350"
            size="35px"
            onClick={() => console.log("Avatar clicked")}
            style={{ cursor: "pointer" }}
          />
        }
      >
        <Toggle value={user.userId} onChange={(e) => onUserChange(e.value!)}>
          {userToggles}
        </Toggle>
      </Popover>
    </div>
  );
};

export default UserAvatar;
