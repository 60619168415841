import { FC } from "react";
import DishSalesTiles from "./dishSalesTiles/dishSalesTiles";
import HeadlineTiles from "./headlineTiles/headlineTiles";
import MenuStatsTiles from "./menuStatsTiles/menuStatsTiles";
import { useSalesMixContext } from "../../context/salesMixContext";
import "./dailySalesMixDashboard.css";

const DailySalesMixDashbord: FC = () => {
  const { salesMixState } = useSalesMixContext();

  return (
    <div className="salesMix-dailySales-container">
      <HeadlineTiles aggregatesSalesData={salesMixState.aggregatedSales} />
      <DishSalesTiles aggregatedSalesData={salesMixState.aggregatedSales} />
      <MenuStatsTiles aggregatedSalesData={salesMixState.aggregatedSales} />
    </div>
  );
};

export default DailySalesMixDashbord;
