import { Item } from "../../../../types/aggregatesSalesApi";
import { PieChartData, PieChart } from "../../../charts/pieChart";
import { getPieChartDataFromItems } from "../dishSalesHelper";
import "./dishSalesPie.css";

type MenuPerformancePieProps = {
  items: Item[];
};

const DishSalesPie: React.FC<MenuPerformancePieProps> = ({ items }) => {
  const pieData: PieChartData[] = getPieChartDataFromItems(items);

  return (
    <div className="dish-sales-pie-container">
      <PieChart
        data={pieData}
        title="Dish Sales"
        series={{ name: "Sales", type: "pie" }}
      />
    </div>
  );
};

export default DishSalesPie;
