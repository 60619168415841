import { useReducer } from "react";
import { ApplicationUser, UserSite } from "../types/user";
import { createCtx } from "../utilities/contextHelper";
import { dummyMcMullensUser, dummyUsers } from "../data/dummyUser";
import { createAction } from "../utilities/createReducerAction";

type UserState = {
  user?: ApplicationUser;
  selectedSite?: UserSite;
};

enum UserStateEvents {
  USER_CHANGE,
  SITE_CHANGE,
}

const userChangeEvent = (userId: string) =>
  createAction(UserStateEvents.USER_CHANGE, userId);
const siteChangeEvent = (site: number) =>
  createAction(UserStateEvents.SITE_CHANGE, site);

type UserChangeEvent = ReturnType<typeof userChangeEvent>;
type SiteChangeEvent = ReturnType<typeof siteChangeEvent>;

type UserStateActions = UserChangeEvent | SiteChangeEvent;

const initialUserState: UserState = {
  user: dummyMcMullensUser,
  selectedSite: dummyMcMullensUser.siteAccess[0],
};

const userReducer = (state: UserState, action: UserStateActions): UserState => {
  switch (action.type) {
    case UserStateEvents.USER_CHANGE:
      return {
        ...state,
        user: dummyUsers.find((u) => u.userId === action.payload),
        selectedSite: dummyUsers.find((u) => u.userId === action.payload)
          ?.siteAccess[0],
      };
    case UserStateEvents.SITE_CHANGE:
      return {
        ...state,
        selectedSite:
          state.user?.siteAccess.find((s) => s.mdrSiteId === action.payload) ??
          state.user!.siteAccess[0],
      };
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
};

interface UserContext {
  //organisation?: Organisation;
  userState: UserState;
  siteChangeOnMdrId: (site: number) => void;
  userChangeOnUserid: (user: string) => void;
}

export const [useUserContext, CtxProvider] = createCtx<UserContext>();

type UserProviderProps = {
  children: React.ReactNode;
};
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [userState, dispatchUserState] = useReducer(
    userReducer,
    initialUserState
  );

  console.log(userState);
  const siteChangeOnMdrId = (site: number) => {
    dispatchUserState(siteChangeEvent(site));
  };

  const userChangeOnUserid = (user: string) => {
    dispatchUserState(userChangeEvent(user));
  };

  // oidc stuff goes in here so we encapsulate all the oidc logic and actions in one place
  // also load the user and organisation data here from the db and persist in context and encode in local storage to its retained

  return (
    <CtxProvider value={{ userState, siteChangeOnMdrId, userChangeOnUserid }}>
      {children}
    </CtxProvider>
  );
};
