enum PeriodTypes {
  DAILY,
  WEEKLY,
  MONTHLY,
}

const periodTypes = [
  { value: PeriodTypes.DAILY, label: "Daily" },
  { value: PeriodTypes.WEEKLY, label: "Weekly" },
  { value: PeriodTypes.MONTHLY, label: "Monthly" },
];

export { PeriodTypes, periodTypes };
