function isString(value: string | number): value is string {
  return typeof value === "string";
}

function isNumber(value: string | number): value is number {
  return typeof value === "number";
}

function isError(value: unknown): value is Error {
  return value instanceof Error;
}

export { isString, isNumber, isError };
