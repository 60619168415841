import { useState } from "react";
import { getBarChartDataForMenuLevel } from "../menuPerformanceHelper";
import Dropdown from "../../../dropDown/dropdown";
import { AggregatedSales } from "../../../../types/aggregatedSales";
import GroupedBarChart from "../../../charts/barChart";
import {
  MenuLevel,
  menuLevelDropdownData,
} from "../../../../constants/menuLevels";
import "./menuPerformanceBar.css";

type MenuPerformanceBarProps = {
  aggregatedSales: AggregatedSales;
};

const MenuPerformanceBar: React.FC<MenuPerformanceBarProps> = ({
  aggregatedSales,
}) => {
  const [menuLevel, setMenuLevel] = useState<MenuLevel>(MenuLevel.Level3MenuID);

  const barData = getBarChartDataForMenuLevel(aggregatedSales, menuLevel);
  return (
    <div className="menu-performance-bar-container">
      <div className="menu-performance-bar-dropdown-container">
        <Dropdown
          data={menuLevelDropdownData}
          valueField="value"
          textField="label"
          value={menuLevel}
          onChange={(e) => setMenuLevel(e.value)}
          width={"150px"}
        />
      </div>
      <GroupedBarChart data={barData} title="Menu Sales" />
    </div>
  );
};

export default MenuPerformanceBar;
