import {
  DonutChartFilled,
  Lightbulb2Filled,
  LineChartSolid2Filled,
  TasksListFilled,
} from "@tag/tag-icons";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import "./homeCards.css";

type CardData = {
  title: string;
  description: string;
  icon: JSX.Element;
  route: string;
  ready: boolean;
};

const cardData: CardData[] = [
  {
    title: "Sales Mix Analysis",
    description:
      "By analyzing your sales mix, you can see which dishes are selling and which aren't. This helps you adjust your menu to customer tastes, and reduce waste boosting efficiency and revenue.",
    icon: <DonutChartFilled size="extraLarge" accent="teal" />, // Replace with actual icon image
    route: "/sales-mix-analysis",
    ready: true,
  },
  {
    title: "Forecasting",
    description:
      "Forecasting sales helps you predict demand, so you can adjust ordering and stock levels accordingly. This ensures you have the right ingredients on hand without overstocking, reducing waste and keeping costs under control.",
    icon: <LineChartSolid2Filled size="extraLarge" accent="teal" />, // Replace with actual icon image
    route: "/forecasting",
    ready: true,
  },
  {
    title: "Prep Lists",
    description:
      "Build kitchen prep lists to help you streamline operations by ensuring you have everything you need, reducing waste, and keeping service running smoothly. It allows you to plan ahead, minimize downtime, and make sure every dish is prepared with the right ingredients at the right time.",
    icon: <TasksListFilled size="extraLarge" accent="teal" />, // Replace with actual icon image
    route: "/prep-lists",
    ready: false,
  },
  {
    title: "Menu Engineering",
    description:
      "Optimize your menu by refining dishes, recipes, and methods while balancing cost, nutrition, allergens, and profitability. Menu engineering ensures every dish is designed to meet customer expectations, maintain efficiency, and maximize margins.",
    icon: <Lightbulb2Filled size="extraLarge" accent="teal" />, // Replace with actual icon image
    route: "/",
    ready: false,
  },
  // Add more cards here as needed
];

type HomeCardsProps = {
  cardData: CardData[];
};

const HomeCards: FC<HomeCardsProps> = ({ cardData }) => {
  const navigate = useNavigate();
  return (
    <div className="bottom-section">
      {cardData.map((card, index) => (
        <div key={index} className="card" onClick={() => navigate(card.route)}>
          {card.icon}
          <h2 className="card-title">{card.title}</h2>
          <p className="card-description">{card.description}</p>
          {card.ready === false ? (
            <div className="coming-soon-overlay">
              <span>Coming Soon</span>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export { cardData, HomeCards };
