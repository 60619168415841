import React from "react";
import backgroundImage from "../../assets/kitchenImage.jpg";
import { cardData, HomeCards } from "../../components/homeCards/homeCards";
import "./home.css";

const HomePage: React.FC = () => {
  return (
    <div className="page-container">
      {/* Top Section */}
      <div
        className="top-section"
        // the image could be loaded dynamically from an API to allow for customization for the customer
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="welcome-text-container">
          <h1 className="welcome-text">Welcome to Kitchen Wizard</h1>
          <h3 className="welcome-sub-text">
            Smarter tools for modern kitchens.
          </h3>
        </div>
      </div>

      {/* Bottom Section */}
      <HomeCards cardData={cardData} />
    </div>
  );
};

export default HomePage;
