/**
 * Truncates a string to a specified length and appends an ellipsis ("...") if the string exceeds that length.
 *
 * @param input - The string to be truncated.
 * @param charToElipsis - The maximum length of the string before truncation.
 * @returns The truncated string with an ellipsis appended if it exceeds the specified length, otherwise the original string.
 */
const elipsisAtLength = (input: string, charToElipsis: number) =>
  input.length > charToElipsis
    ? `${input.substring(0, charToElipsis)}...`
    : input;

/**
 * Uppercase the first letter of a string and converts the rest to lowercase.
 *
 * @param input - The string to transform.
 * @returns The transformed string with the first letter uppercase.
 */
const upperCaseFirstLetter = (input: string): string => {
  if (!input) return ""; // Handle empty string or undefined input.

  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};

export { elipsisAtLength, upperCaseFirstLetter };
