enum MenuLevel {
  Level1MenuID,
  Level2MenuID,
  Level3MenuID,
}

const menuLevelDropdownData: { label: string; value: number }[] = [
  { label: "Menu Level 1", value: MenuLevel.Level1MenuID },
  { label: "Menu Level 2", value: MenuLevel.Level2MenuID },
  { label: "Menu Level 3", value: MenuLevel.Level3MenuID },
];

export { menuLevelDropdownData, MenuLevel };
