import { Pill, Switch } from "@tag/tag-components-react-v4";
import React from "react";
import "./header.css";
import MockButton from "../accessButton/mockButton";
import { MoonLined, WeatherSunLined } from "@tag/tag-icons";
import { useTheme } from "../../context/themeContext";
import { useUserContext } from "../../context/userContext";
import UserAvatar from "../userAvatar/userAvatar";

const Header: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  const { userState, userChangeOnUserid } = useUserContext();
  return (
    <header className="header">
      <div className="header-left-section">
        <MockButton />
        <h3 className="header-app-name">{`Kitchen Wizard`}</h3>
      </div>
      <div className="header-right-section">
        {theme === "dark" ? (
          <MoonLined accent="teal" />
        ) : (
          <WeatherSunLined accent="teal" />
        )}
        <div className="header-theme-switch">
          <Switch accent="teal" onChange={() => toggleTheme()}>
            Theme
          </Switch>
        </div>
        <Pill accent="teal" variant="outlined">
          {userState.user?.organisationName}
        </Pill>
        <UserAvatar user={userState.user!} onUserChange={userChangeOnUserid} />
      </div>
    </header>
  );
};

export default Header;
