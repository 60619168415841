import { FC, useState } from "react";
import ForecastCalendar from "../../components/calendar/forecastCalendar";
import ViewHeader from "../../components/viewHeader/viewHeader";
import { LineChartSolid2Filled } from "@tag/tag-icons";
import dayjs from "dayjs";
import { useForecastContext } from "../../context/forecastContext";
import ForecastMixFilters from "../../components/headerFilters/forecastMixFilters/forecastMixFilters";
import { PeriodTypes } from "../../constants/periodTypes";
import { upperCaseFirstLetter } from "../../utilities/stringHelpers";
import { useUserContext } from "../../context/userContext";

const Forecast: FC = () => {
  const {
    forecastState,
    dateChangeEvent,
    monthChangeEvent,
    periodTypeChangeEvent,
  } = useForecastContext();

  const { userState, siteChangeOnMdrId } = useUserContext();

  const [events, setEvents] = useState<Event[]>([]); // Stores events for the calendar
  const handleAddEvent = (event: Event) => {
    setEvents((prevEvents) => [...prevEvents, event]);
  };

  return (
    <>
      <ViewHeader
        icon={<LineChartSolid2Filled size="medium" accent="teal" />}
        title={`Forecasting \\ ${upperCaseFirstLetter(
          PeriodTypes[forecastState.periodType]
        )} \\ ${userState.selectedSite?.siteName}`}
        filters={
          <ForecastMixFilters
            sites={userState.user!.siteAccess}
            selectedSite={userState.selectedSite?.mdrSiteId}
            periodType={forecastState.periodType}
            currentDate={forecastState.selectedDate}
            onDateChange={dateChangeEvent}
            onMonthChange={monthChangeEvent}
            onPeriodChange={periodTypeChangeEvent}
            onSiteChange={siteChangeOnMdrId}
          />
        }
      />
      <ForecastCalendar currentDate={dayjs(forecastState.selectedDate)} />
    </>
  );
};

export default Forecast;
