import React from "react";
import { Skeleton } from "@tag/tag-components-react-v4";
import MenuPerformancePie from "./menuPerformancePie/menuPerformancePie";
import MenuPerformanceBar from "./menuPerformanceBar/menuPerformanceBar";
import SiteTopDishesList from "./topDishesList/topDishesList";
import "./menuStatsTiles.css";
import { AggregatedSales } from "../../../types/aggregatedSales";

type MenuStatsTilesProps = {
  aggregatedSalesData: AggregatedSales[];
};

const MenuStatsTiles: React.FC<MenuStatsTilesProps> = ({
  aggregatedSalesData,
}) => {
  return (
    <div className="menu-stats-tiles-container">
      <div className="menu-stats-tiles-grid ">
        {aggregatedSalesData.length > 0 ? (
          <>
            <SiteTopDishesList itemData={aggregatedSalesData[1].Items} />
            <MenuPerformancePie aggregatedSales={aggregatedSalesData[1]} />
            <MenuPerformanceBar aggregatedSales={aggregatedSalesData[1]} />
          </>
        ) : (
          <>
            <Skeleton
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            />
            <Skeleton
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            />
            <Skeleton
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MenuStatsTiles;
