import React, { useState } from "react";
import { Skeleton } from "@tag/tag-components-react-v4";
import DishSalesPie from "./dishSalesPie/dishSalesPie";
import DishSalesBar from "./dishSalesBar/dishSalesBar";
import { AggregatedSales } from "../../../types/aggregatedSales";
import Dropdown from "../../dropDown/dropdown";
import {
  ChartType,
  chartTypeDropdownData,
} from "../../../constants/chartTypes";
import "./dishSalesTiles.css";

type DishSalesTilesProps = {
  aggregatedSalesData: AggregatedSales[];
};

const DishSalesTiles: React.FC<DishSalesTilesProps> = ({
  aggregatedSalesData,
}) => {
  const [chartType, setChartType] = useState<ChartType>(ChartType.BAR);

  return (
    <div className="dish-sales-tiles">
      {aggregatedSalesData.length > 0 ? (
        <>
          <div className="dish-sales-chart-container">
            <div className="dish-sales-dropdown-container">
              <Dropdown
                data={chartTypeDropdownData}
                valueField="value"
                textField="label"
                value={chartType}
                onChange={(e) => setChartType(e.value)}
              />
            </div>
            {chartType === ChartType.BAR ? (
              <DishSalesBar items={aggregatedSalesData[1].Items} />
            ) : (
              <DishSalesPie items={aggregatedSalesData[1].Items} />
            )}
          </div>
        </>
      ) : (
        <Skeleton
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            height: "600px",
            width: "100%",
          }}
        />
      )}
    </div>
  );
};

export default DishSalesTiles;
