import {
  Avatar,
  ListItemPrimaryElement,
  ListItemElement,
  Format,
} from "@tag/tag-components-react-v4";
import { CirclePoundFilled, BarChartFilled } from "@tag/tag-icons";
import { FC } from "react";
import { Item } from "../../../../types/aggregatesSalesApi";
import "./topDishesListItem.css";

type TopDishListItemProps = {
  item: Item;
};

const TopDishListItem: FC<TopDishListItemProps> = ({ item }) => {
  return (
    <div className="top-dish-item-container">
      <Avatar
        src="https://media.istockphoto.com/id/1081422898/photo/pan-fried-duck.jpg?s=2048x2048&w=is&k=20&c=KcDScs6hE2epagNeSD5tUuCAomdL1YK0eLLMCd5mtGU="
        placeholder="D"
        size="30px"
        style={{ marginRight: "10px" }}
      />
      <div className="top-dish-item-primary-fields">
        <ListItemPrimaryElement style={{ color: "var(--text-color)" }}>
          {item.Mdr.DishName}
        </ListItemPrimaryElement>
        <ListItemElement className="top-dish-list-text">
          {`${item.Mdr.Level1MenuName} / ${item.Mdr.Level2MenuName} / ${item.Mdr.Level3MenuName}`}
        </ListItemElement>
      </div>
      <div className="top-dish-item-secondary-fields">
        <div className="top-dish-item-seconday-subfield">
          <ListItemElement
            className="top-dish-list-text"
            label="Revenue"
            icon={<CirclePoundFilled size="20px" />}
          >
            <Format value={item.NetAmount} dataType="currency" />
          </ListItemElement>
          <ListItemElement
            className="top-dish-list-text"
            label="Sales"
            icon={<BarChartFilled size="20px" />}
          >
            <Format value={item.QuantitySold} dataType="integer" />
          </ListItemElement>
        </div>
        <div className="top-dish-item-secondary-subfield">
          <ListItemElement className="top-dish-list-text">
            <Format
              value={`${item.NetAmountPercentageInPeriod}%`}
              dataType="string"
            />
          </ListItemElement>
          <ListItemElement className="top-dish-list-text">
            <Format
              value={`${item.QuantitySoldPercentageInPeriod}%`}
              dataType="string"
            />
          </ListItemElement>
        </div>
      </div>
    </div>
  );
};

export default TopDishListItem;
