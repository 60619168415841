interface IFetchParams {
  url: string;
  options?: RequestInit;
}

const baseFetch = async <T>(fetchParams: IFetchParams): Promise<Error | T> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${fetchParams.url}`,
      fetchParams.options
    );

    if (!response.ok) {
      return new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    return new Error(`Fetch error: ${error}`);
  }
};

export { baseFetch };
