import { OrganisationRole } from "../constants/userTypes";
import { ApplicationUser } from "../types/user";

export const dummyMcMullensUser: ApplicationUser = {
  id: "User-e81625b9-8ee1-445b-b029-fef5aa75c54b",
  type: "User",
  pkOrganisationId: "a09db8cd-f6d6-4958-9a1b-1e5b139a2e1b",
  userId: "McMullensAndSons",
  organisationName: "McMullens & Sons",
  fullName: "Evo User",
  active: true,
  lastUpdated: new Date(),
  organisationRoles: [OrganisationRole.ADMIN],
  siteAccess: [
    {
      siteId: "McSite-1",
      roles: OrganisationRole.ADMIN,
      siteName: "Salisbury Arms",
      mdrSiteId: 3014,
    },
    {
      siteId: "McSite-2",
      roles: OrganisationRole.ADMIN,
      siteName: "Crocodile",
      mdrSiteId: 2995,
    },
    {
      siteId: "McSite-3",
      roles: OrganisationRole.ADMIN,
      siteName: "Cricketers Woodford",
      mdrSiteId: 2993,
    },
  ],
};

export const dummyTurtleBayUser: ApplicationUser = {
  id: "User-e81625b9-8ee1-445b-b029-fef5aa75c54b",
  type: "User",
  pkOrganisationId: "a09db8cd-f6d6-4958-9a1b-1e5b139a2e1b",
  userId: "TurtleBay",
  organisationName: "Turtle Bay",
  fullName: "Evo User",
  active: true,
  lastUpdated: new Date(),
  organisationRoles: [OrganisationRole.ADMIN],
  siteAccess: [
    {
      siteId: "TBSite-1",
      roles: OrganisationRole.ADMIN,
      siteName: "Camden",
      mdrSiteId: 12445,
    },
    {
      siteId: "TBSite-2",
      roles: OrganisationRole.ADMIN,
      siteName: "Leicester",
      mdrSiteId: 5810,
    },
    {
      siteId: "TBSite-3",
      roles: OrganisationRole.ADMIN,
      siteName: "Romford",
      mdrSiteId: 11709,
    },
  ],
};

export const dummyUsers = [dummyMcMullensUser, dummyTurtleBayUser];
