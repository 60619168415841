import { Accent } from "@tag/tag-components-react-v4";
import { isNumber, isString } from "../../../../utilities/typeguards";

const iconColor = (
  hasIncreased: boolean,
  hasDecreased: boolean,
  hasChanged: boolean
): { accent: Accent; color: string } => {
  switch (true) {
    case hasIncreased:
      return { accent: "green", color: "rgb(64, 130, 107)" };
    case hasDecreased:
      return { accent: "purple", color: "rgb(151, 48, 96)" };
    case hasChanged:
      return { accent: "blue", color: "rgb(38, 106, 166)" };
    default:
      return { accent: "blue", color: "rgb(38, 106, 166)" };
  }
};

const getFontSize = (text: string): string => {
  let fontSize: string = "2rem";
  if (text.length > 10) return "1.3rem";
  return fontSize;
};

const tileFormatting = (
  value: string | number,
  previousValue: string | number
) => {
  let hasIncreased: boolean = false;
  let hasDecreased: boolean = false;
  let hasChanged: boolean = false;
  let changePercentage: number = 0;
  let iconAccent: { color: string; accent: Accent } = {
    color: "rgb(38, 106, 166)",
    accent: "blue",
  };
  let fontSize: string = "2rem";
  let typeIsNumber: boolean = false;

  if (isNumber(value) && isNumber(previousValue)) {
    hasIncreased = value > previousValue;
    hasDecreased = value < previousValue;
    hasChanged = false;
    changePercentage = ((value - previousValue) / previousValue) * 100;
    iconAccent = iconColor(hasIncreased, hasDecreased, hasChanged);
    typeIsNumber = true;
  }
  if (isString(value) && isString(previousValue)) {
    hasIncreased = false;
    hasDecreased = false;
    hasChanged = value !== previousValue;
    changePercentage = 0;
    iconAccent = iconColor(hasIncreased, hasDecreased, hasChanged);
    typeIsNumber = false;
    fontSize = getFontSize(value);
  }

  return {
    hasIncreased,
    hasDecreased,
    hasChanged,
    changePercentage,
    iconAccent,
    typeIsNumber,
    fontSize,
  };
};

export { iconColor, tileFormatting };
