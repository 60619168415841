import ReactECharts from "echarts-for-react";
import { useTheme } from "../../context/themeContext";

export type ChartSalesData = {
  names: {
    fullName: string[];
    shortenedName: string[];
  };
  netAmount: number[];
  sales: number[];
};

type BarChartProps = {
  data: ChartSalesData;
  title: string;
};

const GroupedBarChart: React.FC<BarChartProps> = ({ data, title }) => {
  const { theme } = useTheme();
  return (
    <ReactECharts
      style={{
        height: "100%",
        width: "100%",
      }}
      option={{
        title: {
          text: title,
          textStyle: {
            color: theme === "dark" ? "#ffffff" : "#4a555f", // Set the title text color here
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: (params: any) => {
            let tooltipContent = "";
            params.forEach((param: any, index: number) => {
              const value =
                index === 0 ? param.value.toFixed(2) : Math.round(param.value);
              tooltipContent += `${param.seriesName}<br>${param.marker}${
                data.names.fullName[param.dataIndex]
              }<span style="float: right; margin-left: 20px"><b>${
                index === 0 ? "£" : ""
              }${value}</b></span><br>`;
            });
            return tooltipContent;
          },
        },
        grid: {
          right: "10%",
          bottom: 100,
        },

        legend: {
          data: ["Revenue", "Sales"],
          orientation: "vertical",
          textStyle: {
            color: theme === "dark" ? "#ffffff" : "#4a555f",
          },
        },
        xAxis: [
          {
            type: "category",
            axisLabel: {
              show: true,
              rotate: 40,
              color: theme === "dark" ? "#ffffff" : "#4a555f",
            },
            axisTick: {
              show: true,
              interval: 0,
            },
            data: data.names.shortenedName,
          },
        ],
        yAxis: [
          {
            type: "value",
            position: "right",
            alignTicks: true,
            offset: 10,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#EFA611",
              },
            },
            axisLabel: {
              formatter: "£ {value}",
            },
          },
          {
            type: "value",
            position: "left",
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#2D864B",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            name: "Revenue",
            type: "bar",
            data: data.netAmount,
          },
          {
            name: "Sales",
            type: "line",
            yAxisIndex: 1,
            data: data.sales,
          },
        ],
      }}
    />
  );
};

export default GroupedBarChart;
