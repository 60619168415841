import React from "react";
import dayjs from "dayjs";
import CalendarDay from "./monthDay";
import "../calendar.css";

type MonthGridProps = {
  currentDate: dayjs.Dayjs;
  events: { date: string; title: string }[];
  onAddEvent: (event: { date: string; title: string }) => void;
};

const MonthGrid: React.FC<MonthGridProps> = ({
  currentDate,
  events,
  onAddEvent,
}) => {
  const startOfMonth = currentDate.startOf("month");
  const endOfMonth = currentDate.endOf("month");
  const daysInMonth = endOfMonth.date();

  // Adjust to start the week on Monday
  const startDayOfWeek = (startOfMonth.day() + 6) % 7; // Shift Sunday (0) to the last position

  const totalCells = Math.ceil((startDayOfWeek + daysInMonth) / 7) * 7; // Total cells (5–6 weeks)
  const days = [];

  // Generate placeholder cells for days before the first day
  for (let i = 0; i < startDayOfWeek; i++) {
    days.push(<div key={`empty-${i}`} className="calendar-placeholder"></div>);
  }

  // Generate days of the month
  for (let i = 1; i <= daysInMonth; i++) {
    const day = startOfMonth.date(i);
    const dayEvents = events.filter(
      (event) => event.date === day.format("YYYY-MM-DD")
    );

    days.push(
      <CalendarDay
        key={day.format("YYYY-MM-DD")}
        day={day}
        events={dayEvents}
        onAddEvent={onAddEvent}
      />
    );
  }

  return (
    <div className="calendar-grid">
      {/* Render weekday headers starting from Monday */}
      {[
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ].map((day, index) => (
        <div key={index} className="calendar-header-cell">
          {day}
        </div>
      ))}
      {/* Render days and placeholders */}
      {days}
    </div>
  );
};

export default MonthGrid;
