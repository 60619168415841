import ReactECharts from "echarts-for-react";
import { FC } from "react";
import "echarts/theme/dark";
import { useTheme } from "../../context/themeContext";

export type PieChartData = {
  id: number;
  name: string;
  shortName: string;
  value: number;
  secondValue: number;
};

type PieChartProps = {
  data: PieChartData[];
  title: string;
  series: { name: string; type: string };
};

export const PieChart: FC<PieChartProps> = ({ data, title, series }) => {
  const { theme } = useTheme();

  return (
    <ReactECharts
      style={{
        height: "100%",
        width: "100%",
        justifySelf: "center",
        justifyContent: "center",
      }}
      option={{
        title: {
          text: title,
          textStyle: {
            color: theme === "dark" ? "#ffffff" : "#4a555f", // Set the title text color here
          },
        },
        tooltip: {
          trigger: "item",
          formatter(params: any) {
            const totalSales = data.reduce((sum, item) => sum + item.value, 0);

            const salesPercentage = (
              (params.value / totalSales) *
              100
            ).toFixed();

            const totalRev = data.reduce(
              (sum, item) => sum + item.secondValue,
              0
            );

            const itemRev =
              data.find((item) => item.id === params.data.id)?.secondValue || 0;

            const revPercentage = ((itemRev / totalRev) * 100).toFixed(2);
            return `${params.seriesName}<br>${params.marker}${
              params.name
            }<br><b>Sales: ${params.value.toFixed(
              0
            )}</b> (${salesPercentage}%)<br><b>Revenue: £${itemRev.toFixed(
              2
            )}</b> (${revPercentage}%)`;
          },
        },
        series: [
          {
            name: series.name,
            type: "pie",
            data: data,
            label: {
              color: theme === "dark" ? "#ffffff" : "#4a555f",
            },
          },
        ],
      }}
    />
  );
};
