import { FC } from "react";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { ThemeProvider } from "./context/themeContext";
import { UserProvider } from "./context/userContext";

const App: FC = () => {
  return (
    <>
      <ThemeProvider>
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
