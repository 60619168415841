import { Accent } from "@tag/tag-components-react-v4";
import { BarChartFilled, CirclePoundFilled } from "@tag/tag-icons";
import React, { FC } from "react";

export enum TileType {
  Revenue,
  Stats,
}

type TileIconProps = {
  tileType: TileType;
  accent: Accent;
};

const TileIcon: FC<TileIconProps> = ({ tileType, accent }) => {
  return (
    <div>
      {tileType === TileType.Revenue ? (
        <CirclePoundFilled size="extraLarge" accent={accent} />
      ) : (
        <BarChartFilled size="extraLarge" accent={accent} />
      )}
    </div>
  );
};

export default TileIcon;
