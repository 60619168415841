import React from "react";
import { tileFormatting } from "./metricTileHelpers";
import TileIcon, { TileType } from "../tileIcon/tileIcon";

type MetricTileProps = {
  title: string;
  value: number | string;
  previousValue: number | string;
  tileType: TileType;
};

const MetricTile: React.FC<MetricTileProps> = ({
  title,
  value,
  previousValue,
  tileType,
}) => {
  const tileProps = tileFormatting(value, previousValue);

  return tileProps.typeIsNumber ? (
    <div style={styles.card}>
      <div style={styles.header}>
        <span style={{ ...styles.title, color: tileProps.iconAccent.color }}>
          {title.toUpperCase()}
        </span>
        <div style={styles.icon}>
          {
            <TileIcon
              tileType={tileType}
              accent={tileProps.iconAccent.accent}
            />
          }
        </div>
      </div>
      <div
        style={{
          ...styles.value,
          color: tileProps.iconAccent.color,
          fontSize: tileProps.fontSize,
        }}
      >
        {value.toLocaleString()}
      </div>
      <div style={styles.comparison}>
        {tileProps.hasIncreased && (
          <span
            style={{ ...styles.increase, color: tileProps.iconAccent.color }}
          >
            ▲ {Math.abs(tileProps.changePercentage).toFixed(1)}% increase from
            yesterday
          </span>
        )}
        {tileProps.hasDecreased && (
          <span
            style={{ ...styles.decrease, color: tileProps.iconAccent.color }}
          >
            ▼ {Math.abs(tileProps.changePercentage).toFixed(1)}% decrease from
            yesterday
          </span>
        )}
        {!tileProps.hasIncreased && !tileProps.hasDecreased && (
          <span style={{ ...styles.same, color: tileProps.iconAccent.color }}>
            No change from yesterday
          </span>
        )}
      </div>
    </div>
  ) : (
    <div style={styles.card}>
      <div style={styles.header}>
        <span style={{ ...styles.title, color: tileProps.iconAccent.color }}>
          {title.toUpperCase()}
        </span>
        <div style={styles.icon}>
          {
            <TileIcon
              tileType={tileType}
              accent={tileProps.iconAccent.accent}
            />
          }
        </div>
      </div>
      <div
        style={{
          ...styles.value,
          color: tileProps.iconAccent.color,
          fontSize: tileProps.fontSize,
        }}
      >
        {value.toLocaleString()}
      </div>
      <div style={styles.comparison}>
        {tileProps.hasChanged && (
          <span
            style={{ ...styles.increase, color: tileProps.iconAccent.color }}
          >
            {`${previousValue.toLocaleString()} was top performing yesterday`}
          </span>
        )}
        {!tileProps.hasChanged && (
          <span style={{ ...styles.same, color: tileProps.iconAccent.color }}>
            No change from yesterday
          </span>
        )}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px",
    backgroundColor: "var(--secondary-background-color)",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    position: "relative",
    border: "1px solid var(--border-color)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    opacity: 0.8,
  },
  icon: {
    fontSize: "1.5rem",
  },
  value: {
    fontSize: "2rem",
    fontWeight: "bold",
    margin: "10px 0",
  },
  comparison: {
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center",
  },
  increase: {
    color: "#4caf50",
    fontWeight: "bold",
  },
  decrease: {
    color: "#f44336",
    fontWeight: "bold",
  },
  same: {
    color: "#999",
  },
};

export default MetricTile;
