import { useState } from "react";
import Dropdown from "../../../dropDown/dropdown";
import { PieChart, PieChartData } from "../../../charts/pieChart";
import { AggregatedSales } from "../../../../types/aggregatedSales";
import { getPieDataForMenuLevel } from "../menuPerformanceHelper";
import {
  MenuLevel,
  menuLevelDropdownData,
} from "../../../../constants/menuLevels";
import "./menuPerformancePie.css";

type MenuPerformancePieProps = {
  aggregatedSales: AggregatedSales;
};

const MenuPerformancePie: React.FC<MenuPerformancePieProps> = ({
  aggregatedSales,
}) => {
  const [menuLevel, setMenuLevel] = useState<MenuLevel>(MenuLevel.Level3MenuID);

  const pieData: PieChartData[] = getPieDataForMenuLevel(
    aggregatedSales,
    menuLevel
  );

  return (
    <div className="menu-performance-pie-container">
      <div className="menu-performance-pie-dropdown-container">
        <Dropdown
          data={menuLevelDropdownData}
          valueField="value"
          textField="label"
          value={menuLevel}
          onChange={(e) => setMenuLevel(e.value)}
          width={"150px"}
        />
      </div>
      <PieChart
        data={pieData}
        title="Sales Mix by Menu"
        series={{ name: "Sales", type: "pie" }}
      />
    </div>
  );
};

export default MenuPerformancePie;
