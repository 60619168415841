import { Item } from "../../../../types/aggregatesSalesApi";
import GroupedBarChart from "../../../charts/barChart";
import { barChartSalesDataFromItems } from "../dishSalesHelper";
import "./dishSalesBar.css";

type DishPerformanceBarProps = {
  items: Item[];
};

const DishSalesBar: React.FC<DishPerformanceBarProps> = ({ items }) => {
  const data = barChartSalesDataFromItems(items);
  return (
    <div className="dish-sales-bar-container">
      <GroupedBarChart data={data} title="Dish Sales" />
    </div>
  );
};

export default DishSalesBar;
