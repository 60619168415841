import { Item } from "../../../types/aggregatesSalesApi";
import { elipsisAtLength } from "../../../utilities/stringHelpers";
import { ChartSalesData } from "../../charts/barChart";
import { PieChartData } from "../../charts/pieChart";

const barChartSalesDataFromItems = (items: Item[]): ChartSalesData => {
  const fullNames: string[] = [];
  const shortNames: string[] = items.map((item) =>
    elipsisAtLength(item.Mdr.DishName, 18)
  );
  const netAmount: number[] = [];
  const sales: number[] = [];

  items.forEach((item) => {
    fullNames.push(item.Mdr.DishName);
    netAmount.push(item.NetAmount);
    sales.push(item.QuantitySold);
  });

  return {
    names: { fullName: fullNames, shortenedName: shortNames },
    netAmount,
    sales,
  };
};

const getPieChartDataFromItems = (items: Item[]): PieChartData[] => {
  return items.map((item) => {
    return {
      id: item.Mdr.DishId,
      name: item.Mdr.DishName,
      shortName: elipsisAtLength(item.Mdr.DishName, 18),
      value: item.QuantitySold,
      secondValue: item.NetAmount,
    };
  });
};

export { barChartSalesDataFromItems, getPieChartDataFromItems };
