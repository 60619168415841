import { createBrowserRouter } from "react-router-dom";
import Home from "./views/home/home";
import Forecast from "./views/forecast/forecast";
import Dishes from "./views/menuEngineering/dishes/dishes";
import MenuEngineering from "./views/menuEngineering/menuEngineering";
import Menus from "./views/menuEngineering/menus/menus";
import Recipes from "./views/menuEngineering/recipes/recipes";
import BaseLayout from "./components/baseLayout/baseLayout";
import SalesMixAnalysis from "./views/salesMix/salesMixAnalysis";
import { SalesMixProvider } from "./context/salesMixContext";
import { ForecastProvider } from "./context/forecastContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },

      {
        path: "sales-mix-analysis",
        children: [
          {
            index: true,
            element: (
              <SalesMixProvider>
                <SalesMixAnalysis />
              </SalesMixProvider>
            ),
          },
        ],
      },
      {
        path: "forecasting",
        element: (
          <ForecastProvider>
            <Forecast />
          </ForecastProvider>
        ),
      },

      {
        path: "menu-engineering",
        element: <MenuEngineering />,
        children: [
          { path: "menus", element: <Menus /> },
          { path: "dishes", element: <Dishes /> },
          { path: "recipes", element: <Recipes /> },
        ],
      },
    ],
  },
]);

export default router;
