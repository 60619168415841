import { DropDownChange, DropDownNative } from "@tag/tag-components-react-v4";

type DropdownProps<T> = {
  data: any[];
  valueField: string;
  textField: string;
  value: T;
  width?: string;
  onChange: (e: DropDownChange<T>) => void;
};

const Dropdown = <T,>({
  data,
  valueField,
  textField,
  value,
  width,
  onChange,
}: DropdownProps<T>): JSX.Element => {
  return (
    <div className="dropdown-container">
      <DropDownNative
        name="myDropdown"
        value={value}
        valueField={valueField}
        textField={textField}
        data={data}
        onChange={(e) => onChange(e)}
        style={{
          width: width ?? "200px",
          backgroundColor: "var(--secondary-background-color)",
          color: "var(--text-color)",
          border: "1px solid var(--border-color)",
        }}
      />
    </div>
  );
};

export default Dropdown;
