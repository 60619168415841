import { useCallback, useEffect, useReducer } from "react";
import { AggregatedSales } from "../types/aggregatedSales";
import { createCtx } from "../utilities/contextHelper";
import { createAction } from "../utilities/createReducerAction";
import { PeriodTypes } from "../constants/periodTypes";
import { fetchAggregatedSales } from "../services/aggregatedSalesService";
import {
  adjustMonths,
  getSearchPeriod,
  adjustDateOnPeriodTypeChange,
} from "../utilities/dateHelpers";
import { useUserContext } from "./userContext";

export type SalesMixState = {
  aggregatedSales: AggregatedSales[];
  periodType: PeriodTypes;
  selectedDate: Date;
  searchPeriod: { from: string; to: string };
};

export enum SalesMixStateEvents {
  SITE_CHANGE,
  PERIOD_TYPE_CHANGE,
  DATE_CHANGE,
  SALES_DATA_CHANGE,
}

const siteChangeEvent = () =>
  createAction(SalesMixStateEvents.SITE_CHANGE, undefined);
const periodTypeChangeEvent = (periodType: PeriodTypes) =>
  createAction(SalesMixStateEvents.PERIOD_TYPE_CHANGE, periodType);
const dateChangeEvent = (date: Date) =>
  createAction(SalesMixStateEvents.DATE_CHANGE, date);
const salesDataChangeEvent = (salesData: AggregatedSales[]) =>
  createAction(SalesMixStateEvents.SALES_DATA_CHANGE, salesData);

type SiteChangeEvent = ReturnType<typeof siteChangeEvent>;
type PeriodTypeChangeEvent = ReturnType<typeof periodTypeChangeEvent>;
type DateChangeEvent = ReturnType<typeof dateChangeEvent>;
type SalesDataChangeEvent = ReturnType<typeof salesDataChangeEvent>;

export type SalesMixStateActions =
  | SiteChangeEvent
  | PeriodTypeChangeEvent
  | DateChangeEvent
  | SalesDataChangeEvent;

const initiaSalesMixState: SalesMixState = {
  aggregatedSales: [],
  periodType: PeriodTypes.DAILY,
  selectedDate: new Date(),
  searchPeriod: getSearchPeriod(PeriodTypes.DAILY, new Date()),
};

export const salesMixReducer = (
  state: SalesMixState,
  action: SalesMixStateActions
): SalesMixState => {
  switch (action.type) {
    case SalesMixStateEvents.SITE_CHANGE:
      return { ...state, aggregatedSales: [] };
    case SalesMixStateEvents.PERIOD_TYPE_CHANGE:
      return {
        ...state,
        periodType: action.payload,
        selectedDate: adjustDateOnPeriodTypeChange(
          action.payload,
          state.selectedDate!
        ),
        searchPeriod: getSearchPeriod(action.payload, state.selectedDate!),
        aggregatedSales: [],
      };
    case SalesMixStateEvents.DATE_CHANGE:
      return {
        ...state,
        selectedDate: action.payload,
        searchPeriod: getSearchPeriod(state.periodType, action.payload),
        aggregatedSales: [],
      };
    case SalesMixStateEvents.SALES_DATA_CHANGE:
      return { ...state, aggregatedSales: action.payload };
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
};

type SalesMixProviderProps = {
  children: React.ReactNode;
};

interface SalesMixContextType {
  salesMixState: SalesMixState;
  periodTypeChangeEvent: (periodType: PeriodTypes) => void;
  monthChangeEvent: (offset: number) => void;
  dateChangeEvent: (date: Date) => void;
}

export const [useSalesMixContext, CtxProvider] =
  createCtx<SalesMixContextType>();

export const SalesMixProvider: React.FC<SalesMixProviderProps> = ({
  children,
}) => {
  const [salesMixState, dispatchSalesMixState] = useReducer(
    salesMixReducer,
    initiaSalesMixState
  );
  const { userState } = useUserContext();

  const periodTypeChangeEvent = (periodType: PeriodTypes) => {
    dispatchSalesMixState({
      type: SalesMixStateEvents.PERIOD_TYPE_CHANGE,
      payload: periodType,
    });
  };

  const siteChangeEvent = () => {
    dispatchSalesMixState({
      type: SalesMixStateEvents.SITE_CHANGE,
      payload: undefined,
    });
  };

  const dateChangeEvent = (date: Date) => {
    dispatchSalesMixState({
      type: SalesMixStateEvents.DATE_CHANGE,
      payload: date,
    });
  };

  const monthChangeEvent = (offset: number) => {
    dispatchSalesMixState({
      type: SalesMixStateEvents.DATE_CHANGE,
      payload: adjustMonths(salesMixState.selectedDate!, offset),
    });
  };

  const fetch = useCallback(async () => {
    if (salesMixState.selectedDate && userState.selectedSite) {
      const agg = await fetchAggregatedSales({
        siteId: userState.selectedSite.mdrSiteId,
        searchPeriod: salesMixState.searchPeriod,
      });
      dispatchSalesMixState({
        type: SalesMixStateEvents.SALES_DATA_CHANGE,
        payload: agg,
      });
    }
  }, [
    salesMixState.searchPeriod,
    salesMixState.selectedDate,
    userState.selectedSite,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  // Clear sales data when selected site changes to ensure fresh data is fetched & skeleton loader appears
  useEffect(() => {
    siteChangeEvent();
  }, [userState.selectedSite]);

  return (
    <CtxProvider
      value={{
        salesMixState,
        periodTypeChangeEvent,
        dateChangeEvent,
        monthChangeEvent,
      }}
    >
      {children}
    </CtxProvider>
  );
};
