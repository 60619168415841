import { upperCaseFirstLetter } from "../utilities/stringHelpers";

enum ChartType {
  BAR,
  PIE,
  TABLE,
}

const chartTypeDropdownData: { label: string; value: number }[] = [
  {
    label: upperCaseFirstLetter(ChartType[ChartType.BAR]),
    value: ChartType.BAR,
  },
  {
    label: upperCaseFirstLetter(ChartType[ChartType.PIE]),
    value: ChartType.PIE,
  },
  {
    label: upperCaseFirstLetter(ChartType[ChartType.TABLE]),
    value: ChartType.TABLE,
  },
];

export { chartTypeDropdownData, ChartType };
