import { DataList } from "@tag/tag-components-react-v4";
import { FC } from "react";
import "./topDishesList.css";
import TopDishListItem from "./topDishListItem";
import { Item } from "../../../../types/aggregatesSalesApi";

type SiteTopDishesListProps = {
  itemData: Item[];
};

const SiteTopDishesList: FC<SiteTopDishesListProps> = ({ itemData }) => {
  const sortByNetAmount = (a: Item, b: Item) => {
    return b.NetAmount - a.NetAmount;
  };

  const sortByQuantitySold = (a: Item, b: Item) => {
    return b.QuantitySold - a.QuantitySold;
  };

  const sortedData = itemData.sort(sortByNetAmount);

  return (
    <div className="top-dishes-list-container">
      <div className="top-dishes-list-header-container">Top Dishes</div>
      <DataList
        renderItem={(dataItem) => <TopDishListItem item={dataItem} />}
        data={sortedData}
        style={{ backgroundColor: "var(--secondary-background-color) " }}
        className="top-dishes-list"
      />
    </div>
  );
};

export default SiteTopDishesList;
