import { FC } from "react";
import "./styledDatePicker.css";
import { DatePicker, DatePickerChange } from "@tag/tag-components-react-v4";

type StyledDatePickerProps = {
  value: Date | undefined;
  onDateChange: (event: DatePickerChange) => void;
};

const StyledDatePicker: FC<StyledDatePickerProps> = ({
  value,
  onDateChange,
}) => {
  return (
    <div className="date-picker-container">
      <DatePicker
        onChange={(e) => onDateChange(e)}
        value={value}
        suppressPortal={true}
        style={{
          backgroundColor: "var(--secondary-background-color)",
          border: "1px solid var(--border-color)",
        }}
      />
    </div>
  );
};

export default StyledDatePicker;
