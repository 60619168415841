import React from "react";
import { Skeleton } from "@tag/tag-components-react-v4";
import {
  AggregatedSales,
  getTopLevel3MenuByNetAmount,
  getTopDishByNetAmount,
} from "../../../types/aggregatedSales";
import { TileMetric } from "../../../types/tileMetric";
import MetricTile from "./metricTile/metricTile";
import { TileType } from "./tileIcon/tileIcon";
import "./headlineTiles.css";

type HeadlineMetricsProps = {
  aggregatesSalesData: AggregatedSales[];
};

const HeadlineTiles: React.FC<HeadlineMetricsProps> = ({
  aggregatesSalesData,
}) => {
  const metrics: TileMetric[] =
    aggregatesSalesData.length > 0
      ? [
          {
            title: "Total Revenue",
            value: aggregatesSalesData[1].NetAmount,
            previousValue: aggregatesSalesData[0].NetAmount ?? 0,
            tileMetricType: TileType.Revenue,
          },
          {
            title: "Total Sales",
            value: aggregatesSalesData[1].QuantitySold,
            previousValue: aggregatesSalesData[0].QuantitySold ?? 0,
            tileMetricType: TileType.Stats,
          },
          {
            title: "Distinct Dishes Sold",
            value: aggregatesSalesData[1].Items.length,
            previousValue: aggregatesSalesData[0].Items.length ?? 0,
            tileMetricType: TileType.Stats,
          },
          {
            title: "Top Menu by Revenue",
            value: getTopLevel3MenuByNetAmount(aggregatesSalesData[1].Items),
            previousValue: getTopLevel3MenuByNetAmount(
              aggregatesSalesData[0].Items
            ),
            tileMetricType: TileType.Revenue,
          },
          {
            title: "Top Dish by Revenue",
            value: getTopDishByNetAmount(aggregatesSalesData[1].Items),
            previousValue: getTopDishByNetAmount(aggregatesSalesData[0].Items),
            tileMetricType: TileType.Revenue,
          },
        ]
      : [];

  return (
    <div className="headline-tiles-container">
      <div className="headline-tiles-grid">
        {metrics.length > 0 ? (
          metrics.map((metric, index) => (
            <MetricTile
              key={index}
              title={metric.title}
              value={metric.value}
              previousValue={metric.previousValue}
              tileType={metric.tileMetricType}
            />
          ))
        ) : (
          <>
            <Skeleton
              style={{
                height: "200px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            />
            <Skeleton
              style={{
                height: "200px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            />
            <Skeleton
              style={{
                height: "200px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            />
            <Skeleton
              style={{
                height: "200px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            />
            <Skeleton
              style={{
                height: "200px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default HeadlineTiles;
