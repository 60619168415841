import { FC } from "react";
import Header from "../header/header";
import { Outlet } from "react-router-dom";
import SideNavigation from "../sideNaviation/sideNavigation";
import "./baseLayout.css";

const BaseLayout: FC = () => {
  return (
    <div className="main-container">
      <Header />
      <div className="content-container">
        <SideNavigation />
        <div className="view-container">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default BaseLayout;
