import { AggregatedSales } from "../types/aggregatedSales";
import { createCtx } from "../utilities/contextHelper";
import { createAction } from "../utilities/createReducerAction";
import { PeriodTypes } from "../constants/periodTypes";
import {
  adjustMonths,
  getSearchPeriod,
  adjustDateOnPeriodTypeChange,
} from "../utilities/dateHelpers";
import { useEffect, useReducer } from "react";
import { useUserContext } from "./userContext";

export type ForecastState = {
  aggregatedSales: AggregatedSales[];
  periodType: PeriodTypes;
  selectedDate: Date;
  searchPeriod: { from: string; to: string };
};

export enum ForecastStateEvents {
  SITE_CHANGE,
  PERIOD_TYPE_CHANGE,
  DATE_CHANGE,
}

export const siteChangeEvent = () =>
  createAction(ForecastStateEvents.SITE_CHANGE, undefined);
export const periodTypeChangeEvent = (periodType: PeriodTypes) =>
  createAction(ForecastStateEvents.PERIOD_TYPE_CHANGE, periodType);
export const dateChangeEvent = (date: Date) =>
  createAction(ForecastStateEvents.DATE_CHANGE, date);

type SiteChangeEvent = ReturnType<typeof siteChangeEvent>;
type PeriodTypeChangeEvent = ReturnType<typeof periodTypeChangeEvent>;
type DateChangeEvent = ReturnType<typeof dateChangeEvent>;

export type ForecastStateActions =
  | SiteChangeEvent
  | PeriodTypeChangeEvent
  | DateChangeEvent;

const initialForecastState: ForecastState = {
  aggregatedSales: [],
  periodType: PeriodTypes.DAILY,
  selectedDate: new Date(),
  searchPeriod: getSearchPeriod(PeriodTypes.DAILY, new Date()),
};

export const forecastReducer = (
  state: ForecastState,
  action: ForecastStateActions
): ForecastState => {
  switch (action.type) {
    case ForecastStateEvents.SITE_CHANGE:
      return { ...state, aggregatedSales: [] };
    case ForecastStateEvents.PERIOD_TYPE_CHANGE:
      return {
        ...state,
        periodType: action.payload,
        selectedDate: adjustDateOnPeriodTypeChange(
          action.payload,
          state.selectedDate!
        ),
        searchPeriod: getSearchPeriod(action.payload, state.selectedDate!),
        aggregatedSales: [],
      };
    case ForecastStateEvents.DATE_CHANGE:
      return {
        ...state,
        selectedDate: action.payload,
        searchPeriod: getSearchPeriod(state.periodType, action.payload),
        aggregatedSales: [],
      };
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
};

type FoecastProviderProps = {
  children: React.ReactNode;
};

interface ForecastContextType {
  forecastState: ForecastState;
  periodTypeChangeEvent: (periodType: PeriodTypes) => void;
  monthChangeEvent: (offset: number) => void;
  dateChangeEvent: (date: Date) => void;
}

export const [useForecastContext, CtxProvider] =
  createCtx<ForecastContextType>();

export const ForecastProvider: React.FC<FoecastProviderProps> = ({
  children,
}) => {
  const [forecastState, dispatchForecastState] = useReducer(
    forecastReducer,
    initialForecastState
  );

  const { userState } = useUserContext();

  const periodTypeChangeEvent = (periodType: PeriodTypes) => {
    dispatchForecastState({
      type: ForecastStateEvents.PERIOD_TYPE_CHANGE,
      payload: periodType,
    });
  };

  const siteChangeEvent = () => {
    dispatchForecastState({
      type: ForecastStateEvents.SITE_CHANGE,
      payload: undefined,
    });
  };

  const dateChangeEvent = (date: Date) => {
    dispatchForecastState({
      type: ForecastStateEvents.DATE_CHANGE,
      payload: date,
    });
  };

  const monthChangeEvent = (offset: number) => {
    dispatchForecastState({
      type: ForecastStateEvents.DATE_CHANGE,
      payload: adjustMonths(forecastState.selectedDate!, offset),
    });
  };

  useEffect(() => {
    siteChangeEvent();
  }, [userState.selectedSite]);

  return (
    <CtxProvider
      value={{
        forecastState,
        periodTypeChangeEvent,
        dateChangeEvent,
        monthChangeEvent,
      }}
    >
      {children}
    </CtxProvider>
  );
};
