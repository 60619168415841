import React, { useState } from "react";
import dayjs from "dayjs";
import MonthGrid from "./month/monthGrid";
import "./calendar.css";

export interface Event {
  date: string;
  title: string;
}

type ForecastCalendarProps = {
  currentDate: dayjs.Dayjs;
};

const ForecastCalendar: React.FC<ForecastCalendarProps> = ({ currentDate }) => {
  const [events, setEvents] = useState<Event[]>([]); // Stores events for the calendar

  const handleAddEvent = (event: Event) => {
    setEvents((prevEvents) => [...prevEvents, event]);
  };

  return (
    <div className="calendar">
      <MonthGrid
        currentDate={currentDate}
        events={events}
        onAddEvent={handleAddEvent}
      />
    </div>
  );
};

export default ForecastCalendar;
