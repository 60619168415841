import React from "react";
import "./viewHeader.css";

type ViewHeaderProps = {
  icon: React.ReactElement;
  title: string;
  filters?: React.ReactElement;
};

const ViewHeader: React.FC<ViewHeaderProps> = ({ icon, title, filters }) => {
  return (
    <div className="view-header">
      <div className="view-header-title-container">
        {icon}
        <h4>{title}</h4>
      </div>
      <div className="view-header-filters-container">{filters}</div>
    </div>
  );
};

export default ViewHeader;
