import { MenuLevel } from "../../../constants/menuLevels";
import {
  AggregatedSales,
  MenuAggregateStatistics,
} from "../../../types/aggregatedSales";
import { elipsisAtLength } from "../../../utilities/stringHelpers";
import { ChartSalesData } from "../../charts/barChart";
import { PieChartData } from "../../charts/pieChart";

const getMenuLevelData = (salesData: AggregatedSales, menuLevel: MenuLevel) => {
  let menuLevelData: MenuAggregateStatistics[];
  switch (menuLevel) {
    case MenuLevel.Level1MenuID:
      menuLevelData = salesData.Level1MenuAggregateStatistics;
      break;
    case MenuLevel.Level2MenuID:
      menuLevelData = salesData.Level2MenuAggregateStatistics;
      break;
    case MenuLevel.Level3MenuID:
      menuLevelData = salesData.Level3MenuAggregateStatistics;
      break;
    default:
      throw new Error("Unsupported MeuLevel");
  }
  return menuLevelData;
};

const barChartSalesDataFromMenuAggregate = (
  menuStats: MenuAggregateStatistics[]
): ChartSalesData => {
  const fullNames: string[] = [];
  const shortNames: string[] = menuStats.map((menuStat) =>
    elipsisAtLength(menuStat.name, 18)
  );
  const netAmount: number[] = [];
  const sales: number[] = [];

  menuStats.forEach((menuStats) => {
    fullNames.push(menuStats.name);
    netAmount.push(menuStats.netAmount);
    sales.push(menuStats.quantitySold);
  });

  return {
    names: { fullName: fullNames, shortenedName: shortNames },
    netAmount,
    sales,
  };
};

const getPieDataForMenuLevel = (
  salesData: AggregatedSales,
  menuLevel: MenuLevel
): PieChartData[] => {
  const menuLevelData = getMenuLevelData(salesData, menuLevel);
  return menuLevelData.map((item) => {
    return {
      id: item.id,
      name: item.name,
      shortName: item.shorttenedName,
      value: item.quantitySold,
      secondValue: item.netAmount,
    };
  });
};

const getBarChartDataForMenuLevel = (
  salesData: AggregatedSales,
  menuLevel: MenuLevel
): ChartSalesData => {
  const menuLevelData = getMenuLevelData(salesData, menuLevel);
  return barChartSalesDataFromMenuAggregate(menuLevelData);
};

export { getPieDataForMenuLevel, getBarChartDataForMenuLevel };
