import { FC } from "react";
import accessLogo from "../../assets/accessLogo.png"; // Adjust the path as necessary
import "./mockButton.css"; // Adjust the path as necessary
import { ChevronDownLined } from "@tag/tag-icons";

const MockButton: FC = () => {
  return (
    <div className="mockButtonContainer">
      <img className="mockButton" src={accessLogo} alt="Company Icon" />
      <ChevronDownLined size="small" />
    </div>
  );
};

export default MockButton;
