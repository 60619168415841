import dayjs from "dayjs";
import { PeriodTypes } from "../constants/periodTypes";

/**
 * Returns a new Date object set to the first day of the month of the given date.
 *
 * @param date - The original Date object.
 * @returns A new Date object set to the first day of the month.
 */
const getFirstOfMonth = (date: Date): Date => {
  const firstDay = new Date(date);
  firstDay.setDate(1);

  return firstDay;
};

/**
 * Returns the Date object representing the Monday of the current week for the given date.
 *
 * @param date - The input date for which to find the Monday of the current week.
 * @returns The Date object representing the Monday of the current week.
 */
const getMondayOfCurrentWeek = (date: Date): Date => {
  const currentDate = new Date(date);
  // Get the day of the week (0 is Sunday, 6 is Saturday)
  const dayOfWeek = currentDate.getDay();
  // Calculate the number of days to subtract to get to Monday
  const daysToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
  currentDate.setDate(currentDate.getDate() + daysToMonday);

  return currentDate;
};

/**
 * Adjusts the given date by a specified number of weeks.
 *
 * @param date - The original date to be adjusted.
 * @param weeksOffset - The number of weeks to adjust the date by. Positive values move the date forward, negative values move it backward.
 * @returns A new Date object adjusted by the specified number of weeks.
 */
const adjustWeek = (date: Date, weeksOffset: number): Date => {
  const adjustedDate = new Date(date);
  adjustedDate.setDate(date.getDate() + weeksOffset * 7);

  return adjustedDate;
};

/**
 * Adjusts the given date by a specified number of days.
 *
 * @param date - The original date to be adjusted.
 * @param daysOffset - The number of days to adjust the date by. Positive values move the date forward, negative values move it backward.
 * @returns A new `Date` object adjusted by the specified number of days.
 */
const adjustDays = (date: Date, daysOffset: number): Date => {
  const adjustedDate = new Date(date);
  adjustedDate.setDate(date.getDate() + daysOffset);

  return adjustedDate;
};

/**
 * Adjusts the given date by a specified number of months.
 *
 * @param date - The original date to be adjusted.
 * @param monthsOffset - The number of months to adjust the date by. Can be positive or negative.
 * @returns A new Date object with the adjusted month.
 */
const adjustMonths = (date: Date, monthsOffset: number): Date => {
  const adjustedDate = new Date(date);
  adjustedDate.setMonth(date.getMonth() + monthsOffset);
  return adjustedDate;
};

/**
 * Adjusts the given date based on the specified period type.
 *
 * @param periodType - The type of period to adjust the date for. Can be DAILY, WEEKLY, or MONTHLY.
 * @param selectedDate - The date to be adjusted.
 * @returns The adjusted date based on the period type.
 *
 * - For DAILY, returns the original date.
 * - For WEEKLY, returns the date of the Monday of the current week.
 * - For MONTHLY, returns the first day of the month.
 */
const adjustDateOnPeriodTypeChange = (
  periodType: PeriodTypes,
  selectedDate: Date
) => {
  // Clone the date to avoid modifying the original
  const date = new Date(selectedDate);
  // Adjust the date based on the period type
  switch (periodType) {
    case PeriodTypes.DAILY:
      return date;
    case PeriodTypes.WEEKLY:
      return getMondayOfCurrentWeek(date);
    case PeriodTypes.MONTHLY:
      return getFirstOfMonth(date);
    default:
      return date;
  }
};

/**
 * Generates a search period based on the given period type and selected date.
 *
 * @param periodType - The type of period to generate (e.g., daily, weekly, monthly).
 * @param selectedDate - The date from which the period calculation starts.
 * @returns An object containing the start (`from`) and end (`to`) dates of the search period in the format "YYYY-MM-DDTHH:mm:ss".
 * @throws Will throw an error if the period type is invalid.
 */
const getSearchPeriod = (
  periodType: PeriodTypes,
  selectedDate: Date
): { from: string; to: string } => {
  let from = new Date(selectedDate);
  let to = new Date(selectedDate);

  switch (periodType) {
    case PeriodTypes.DAILY:
      from = adjustDays(from, -1);
      from.setHours(0, 0, 0, 0);
      to = adjustDays(to, 1);
      to.setHours(0, 0, 0, 0);
      return {
        from: dayjs(from).format("YYYY-MM-DDTHH:mm:ss"),
        to: dayjs(to).format("YYYY-MM-DDTHH:mm:ss"),
      };
    case PeriodTypes.WEEKLY:
      from = getMondayOfCurrentWeek(from);
      from.setHours(0, 0, 0, 0);
      to = adjustDays(from, 7);
      to.setHours(0, 0, 0, 0);
      return {
        from: dayjs(from).format("YYYY-MM-DDTHH:mm:ss"),
        to: dayjs(to).format("YYYY-MM-DDTHH:mm:ss"),
      };
    case PeriodTypes.MONTHLY:
      from = getFirstOfMonth(from);
      from.setHours(0, 0, 0, 0);
      to = adjustMonths(from, 1);
      to.setHours(0, 0, 0, 0);
      return {
        from: dayjs(from).format("YYYY-MM-DDTHH:mm:ss"),
        to: dayjs(to).format("YYYY-MM-DDTHH:mm:ss"),
      };
    default:
      throw new Error("Invalid period type");
  }
};

export {
  getMondayOfCurrentWeek,
  getFirstOfMonth,
  adjustWeek,
  adjustDays,
  adjustMonths,
  adjustDateOnPeriodTypeChange,
  getSearchPeriod,
};
